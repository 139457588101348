import type { NursingHomeProfileDto } from '@pflegenavi/shared/api';
import { createContext, useContext, useState, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';

interface NursingHomeContextProps {
  selectedNursingHome: NursingHomeProfileDto | undefined;
  initNursingHome: (NursingHome: NursingHomeProfileDto) => Promise<void>;
  nursingHomeList: NursingHomeProfileDto[];
  // TODO: Why are we not fetching the nursing home list inside this NursingHomeProvider?
  setNursingHomeList: (nursingHomeList: NursingHomeProfileDto[]) => void;
}

export const NursingHomeContext = createContext<
  NursingHomeContextProps | undefined
>(undefined);

export const useNursingHomeContext = (): NursingHomeContextProps => {
  const context = useContext(NursingHomeContext);
  if (!context) {
    throw new Error('Missing NursingHomeProvider!');
  }
  return context;
};

export const useNursingHomeContextWithSuspense = (): Omit<
  NursingHomeContextProps,
  'selectedNursingHome'
> & {
  selectedNursingHome: NursingHomeProfileDto;
} => {
  const context = useNursingHomeContext();
  const [isWaiting, setIsWaiting] = useState(
    () => !context.selectedNursingHome
  );
  // Add a counter to track suspension attempts
  const suspensionCounter = useRef(0);

  // Update isWaiting when selectedNursingHome changes
  useEffect(() => {
    if (context.selectedNursingHome && isWaiting) {
      setIsWaiting(false);
    }
  }, [context.selectedNursingHome, isWaiting]);

  if (isWaiting) {
    // Increment the counter before suspending
    suspensionCounter.current += 1;

    // Log to Sentry if suspended too many times
    if (suspensionCounter.current > 5) {
      Sentry.captureMessage(
        `NursingHomeContextWithSuspense suspended ${suspensionCounter.current} times`,
        {
          level: 'warning',
          tags: {
            component: 'NursingHomeContextWithSuspense',
          },
          extra: {
            suspensionCounter: suspensionCounter.current,
          },
        }
      );
    }

    throw new Promise((resolve) => {
      setTimeout(resolve, 50);
    });
  }

  return {
    ...context,
    selectedNursingHome: context.selectedNursingHome as NursingHomeProfileDto,
  };
};
