import type { FC } from 'react';
import * as React from 'react';
import { useState } from 'react';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FileInput } from '@pflegenavi/web-components';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { ReceiptBatchImagesRow } from './ReceiptBatchImagesRow';
import type {
  UploadFile,
  UploadState,
  UseReceiptImagesType,
} from '@pflegenavi/shared-frontend/platform';

interface ReceiptBatchOverviewProps {
  receiptsCount: number;
  totalAmount: number;
  ReceiptBatchImageInput: React.ReactElement;
}

export const ReceiptBatchOverview: FC<ReceiptBatchOverviewProps> = ({
  receiptsCount,
  totalAmount,
  ReceiptBatchImageInput,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  return (
    <Grid item xs={12} md={6} lg={3.1}>
      <Card
        sx={{
          p: 2,
          height: '100%',
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <OverviewItem
            label={t('common.receipts_plural')}
            unit="#"
            value={receiptsCount.toString()}
          />
          <OverviewItem
            align={'right'}
            label={t('common.total-amount')}
            unit="€"
            value={fCurrencyCents(totalAmount < 10000000 ? totalAmount : 0)
              .replace('€', '')
              .trim()}
          />
        </Stack>
        {ReceiptBatchImageInput}
      </Card>
    </Grid>
  );
};

interface OverviewItemProps {
  label: string;
  unit: string; // can be # or €
  value: string;
  align?: 'left' | 'right';
}

const OverviewItem: FC<OverviewItemProps> = ({ label, unit, value, align }) => {
  return (
    <Stack>
      <Typography
        variant="caption"
        color="grey.500"
        fontWeight={700}
        sx={{
          textTransform: 'uppercase',
          textAlign: align,
        }}
      >
        {label}
      </Typography>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent={align === 'right' ? 'flex-end' : 'flex-start'}
      >
        <Typography
          variant="body2"
          fontWeight={700}
          sx={{
            mb: 0.7,
            mr: 0.5,
          }}
        >
          {unit}
        </Typography>
        <Typography variant="h4" fontWeight={700} sx={{ textAlign: align }}>
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

export interface ReceiptBatchImageInputProps {
  batchImageIds: string[];
  uploadFile: UploadFile;
  uploadState: UploadState | undefined;
  handleSetBatchImages: (images: string[]) => void;
  useReceiptImages: UseReceiptImagesType;
  autoGenerateReceipts?: boolean;
  selectedNursingHomeId?: string;
  appMode?: 'service-provider' | 'nursing-home';
  handleCopyReceipts?: () => void;
}

export const ReceiptBatchImageInput: FC<ReceiptBatchImageInputProps> = ({
  batchImageIds,
  uploadFile,
  handleSetBatchImages,
  useReceiptImages,
  autoGenerateReceipts,
  selectedNursingHomeId,
  appMode,
  handleCopyReceipts,
}) => {
  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);

  const handleAddFile = async (file: File | undefined) => {
    if (!file) {
      return;
    }
    setUploading(true);
    const imageId = await uploadFile(file);
    if (imageId) {
      handleSetBatchImages([...batchImageIds, imageId]);
    }
    setUploading(false);
  };

  return (
    <Stack sx={{ mt: 3 }}>
      <Typography
        variant="caption"
        color="grey.500"
        fontWeight={700}
        sx={{
          textTransform: 'uppercase',
          mb: 1,
        }}
      >
        {t('receipts.batch.batch-receipt')}
      </Typography>

      <ReceiptBatchImagesRow
        count={batchImageIds.length}
        batchImageIds={batchImageIds}
        handleDelete={() => handleSetBatchImages([])}
        useReceiptImages={useReceiptImages}
        handleCopyReceipts={handleCopyReceipts}
        autoGenerateReceipts={autoGenerateReceipts}
      />

      {!autoGenerateReceipts && (
        <FileInput
          loading={uploading}
          setFile={handleAddFile}
          label={t(
            `actions.${
              batchImageIds.length > 0
                ? 'upload-additional-receipt'
                : 'upload-receipt'
            }`
          )}
          disabled={appMode === 'service-provider' && !selectedNursingHomeId}
          disabledMessage={t(
            'receipts.batch.upload-disabled-missing-nursing-home'
          )}
        />
      )}
    </Stack>
  );
};
